import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/default/DefaultTemplate.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Text = makeShortcode("Text");
const Link = makeShortcode("Link");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`We’ve developed a cookie policy (“Cookie Policy”) in order to explain how we use cookies and similar technologies (together, “Cookies”) on this website (our “Website”), and to demonstrate our firm commitment to the privacy of your personal information.`}</p>
    <p>{`The first time that you visit our Website, we notify you about our use of Cookies through a notification banner. By continuing to use our Website, you consent to our use of Cookies as described in this Cookie Policy. However, you can choose whether or not to continue accepting Cookies at any later time. Information on how to manage Cookies is set out later in this Cookie Policy.`}</p>
    <p>{`Please note that our use of any personal information we collect about you is subject to our Privacy Policy.`}</p>
    <h3>{`What are Cookies?`}</h3>
    <p>{`Cookies are small text files containing user IDs that are automatically placed on your computer or other device when you visit a website. The Cookies are stored by the internet browser. The browser sends the Cookies back to the website on each subsequent visit, allowing the website to recognize your computer or other device. This recognition enables the website provider to observe your activity on the website, deliver a personalized, responsive service and improve the website. Cookies can be Session Cookies or Persistent Cookies. Session Cookies allow a website to link a series of your actions during one browser session, for example to remember the items you have added to a shopping basket. Session Cookies expire after a browser session and are therefore not stored on your computer or other device afterwards. Persistent Cookies are stored on your computer or other device between browser sessions and can be used when you make subsequent visits to the website, for example to remember your website preferences, such as language or font size.`}</p>
    <h3>{`Cookies We Use and Their Purpose`}</h3>
    <p>{`We use four types of Cookies—Strictly Necessary Cookies, Performance Cookies, Functionality Cookies, and Targeting or Advertising Cookies. Each type of Cookie and the purposes for which we use them are described in this section. You can find set out below a list of those Cookies used by HYH Studio. We use the following types of Cookies:`}</p>
    <h4>{`Strictly Necessary Cookies`}</h4>
    <p>{`Strictly Necessary Cookies enable you to move around our Website and use essential features. For example, if you log into our Website, we use a Cookie to keep you logged in and allow you to access restricted areas, without you having to repeatedly enter your login details. If you are registering for or purchasing a product or service, we will use Cookies to remember your information and selections, as you move through the registration or purchase process.`}</p>
    <p>{`Strictly Necessary Cookies are necessary for our Website to provide you with a full service. If you disable them, certain essential features of our Website will not be available to you and the performance of our Website will be impeded.`}</p>
    <h4>{`Performance Cookies`}</h4>
    <p>{`Performance Cookies collect information about how you use our Website, for example which pages you visit and if you experience any errors. These Cookies don’t collect any information that could identify you—all the information collected is anonymous. We may use these Cookies to help us understand how you use our Website and assess how well our Website performs and how we could improve it for you. We may also use such Cookies to discover which parts of our Website interest you and measure how effective our advertising is.`}</p>
    <h4>{`Functionality Cookies`}</h4>
    <p>{`Functionality Cookies enable a website to provide you with specific services or a customized experience. We may use these Cookies to provide you with services such as watching a video or adding user comments. We may also use such Cookies to remember changes you make to your settings or preferences (for example, changes to text size or your choice of language or region) or offer you time-saving or personalized features. You can control whether or not Functionality Cookies are used but disabling them may mean we are unable to provide you with some services or features of our Website.`}</p>
    <h4>{`Targeting or Advertising Cookies`}</h4>
    <p>{`Targeting or Advertising Cookies enable a website to show you relevant advertising or facilitate your use of third-party services. We may use these Cookies to provide information about your Website visit to advertisers, so that they can show you ads more relevant to your interests, limit the number of times you see the same advert and measure the effectiveness of their advertising. We may also use such Cookies to make it easier for you to ‘like’ or ‘share’ our Website content through your social network(s). The social networking websites may subsequently use information about your visit to target advertising to you on those websites. Please see their website terms and policies for further information on such use. You can control whether or not Targeting or Advertising Cookies are used but disabling them may mean we are unable to provide you with some services or features of our Website.`}</p>
    <h3>{`First and Third-Party Cookies`}</h3>
    <p>{`Cookies placed on your computer or other device include First Party Cookies, meaning Cookies that are placed there by us, or by third party service providers acting on our behalf. Where such Cookies are being managed by third parties, we only allow the third parties to use the Cookies for our purposes, as described in this Cookie Policy, and not for their own purposes.`}</p>
    <p>{`Cookies placed on your computer or other device may also include Third Party Cookies, meaning Cookies that are placed there by third parties. These Cookies may include third party advertisers who display adverts on our Website and/or social network providers who provide ‘like’ or ‘share’ capabilities (see the above section on Targeting or Advertising Cookies). They may also include third parties who provide video content which is embedded on our Website (such as YouTube). Please see the website terms and policies of these third parties for further information on their use of Cookies.`}</p>
    <h3>{`Managing Cookies`}</h3>
    <p>{`You always have a choice whether or not to accept Cookies. When you first visit our Website and we notify you about our use of Cookies, you can choose not to consent to such use. If you continue to use our Website, you are consenting to our use of Cookies for the time being. However, you can choose not to continue accepting Cookies at any later time. In this section, we describe ways to manage Cookies, including how to disable them.`}</p>
    <p>{`You can manage Cookies through the settings of your internet browser. You can choose to block or restrict Cookies from being placed on your computer or other device. You can also periodically review the Cookies that have been placed there and disable some or all of them.`}</p>
    <p>{`You can learn more about how to manage Cookies on the following websites: `}<a parentName="p" {...{
        "href": "http://www.allaboutcookies.org"
      }}>{`www.allaboutcookies.org`}</a>{` and `}<a parentName="p" {...{
        "href": "http://www.youronlinechoices.com"
      }}>{`www.youronlinechoices.com`}</a>{`.`}</p>
    <p>{`Please be aware that if you choose not to accept certain Cookies, it may mean we are unable to provide you with some services or features of our Website.`}</p>
    <h3>{`Flash Cookies and How to Manage Them`}</h3>
    <p>{`We may provide videos and other content through our Website using Adobe Flash Player, which uses Flash Cookies (also known as Local Shared Objects) to provide useful features such as auto-resume and the ability to save your preferences. Flash Cookies are placed on your computer or other device in much the same way as other Cookies. However, it is not possible to manage them in the same way through your internet browser settings. You can learn more about how to manage Flash Cookies on `}<a parentName="p" {...{
        "href": "http://www.allaboutcookies.org"
      }}>{`www.allaboutcookies.org`}</a>{`.`}</p>
    <p>{`Please be aware that if you choose not to accept Flash Cookies, you will be unable to view certain content, such as videos.`}</p>
    <h3>{`Changes to Cookie Policy`}</h3>
    <p>{`In order to keep up with changing legislation and best practice, we may revise this Cookie Policy at any time without notice by posting a revised version on our Website. Please check back periodically so that you are aware of any changes.`}</p>
    <h3>{`Questions or Concerns`}</h3>
    <Text as="p" mdxType="Text">
  If you have any questions or concerns about this Cookie Policy or our use of
  Cookies on our Website, please contact us by email to{" "}
  <Link href="mailto:info@hyh.studio." size="inherit" mdxType="Link">
    info@hyh.studio
  </Link>
    </Text>
    <sup>*</sup>If you need to review our policy in another language, please review
it in Google Translate or a similar platform. HYH Studio is not responsible for
any information lost in translation.

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      